<footer class="cv__footer footer cv-content-wrapper">
    <div class="footer__content">
        <div class="footer__content-item logo-item">
            <img src="/assets/images/logo.png" alt="#" id="footer-logo">
        </div>
        <div class="footer__content-item hidden">
            <div class="fw-700 color-black2C fs-12 title-content-margin">Жильё</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">Аренда недвижимости</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">Покупка недвижимости</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">Покупка земли</div>
        </div>
        <div class="footer__content-item hidden">
            <div class="fw-700 color-black2C fs-12 title-content-margin">Авто и байки</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">Покупка авто и байков</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">Аренда авто и байков</div>
        </div>
        <div class="footer__content-item hidden">
            <div class="fw-700 color-black2C fs-12 title-content-margin">Прочее</div>
            <!-- <div class="fw-400 color-black63 fs-12 item-content-margin">Афиша</div> -->
            <a target="_blank" class="fw-400 item-content-margin color-black63 link-hover fs-12" routerLink="/tour">Экскурсии</a>
            <a target="_blank" class="fw-400 item-content-margin color-black63 link-hover fs-12" routerLink="/yachts">Аренда Яхт</a>
            <a target="_blank" class="fw-400 item-content-margin color-black63 link-hover fs-12" routerLink="/services">Услуги</a>
            <!-- <div class="fw-400 color-black63">Визы</div> -->
        </div>
        <div class="footer__content-item">
            <a target="_blank" class="fw-700 color-black2C fs-12 title-content-margin link-hover" routerLink="/static">О нас</a>
            <a target="_blank" class="fw-400 color-black63 fs-12 item-content-margin link-hover" routerLink="/static/contacts">Контакты</a>
            <a target="_blank" class="fw-400 color-black63 fs-12 item-content-margin link-hover" routerLink="/static/partners">Сотрудничество</a>
            <a target="_blank" class="fw-400 color-black63 fs-12 item-content-margin link-hover" routerLink="/static/faq">FAQ</a>
            <a target="_blank" class="fw-400 color-black63 fs-12 item-content-margin link-hover" routerLink="/static/review">Отзывы</a>
            <a target="_blank" class="fw-400 color-black63 fs-12 item-content-margin link-hover" routerLink="/static/blog">Блог</a>
            <a target="_blank" class="fw-400 color-black63 fs-12 item-content-margin link-hover" routerLink="/static/team">Команда</a>
            <a target="_blank" class="fw-400 color-black63 fs-12 item-content-margin link-hover" routerLink="/static/vacancies">Вакансии</a>
        </div>
        <div class="footer__content-item">
            <div class="fw-700 color-black2C fs-12 title-content-margin">Свяжитесь с нами</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">+7 495 149 05 00</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">+66 2 132 1888</div>
            <div class="fw-400 color-black63 fs-12 item-content-margin">supportthai.pro</div>
        </div>
    </div>
    <div class="copyright"><div class="copy_one">Copyright 2024 © «Thai.PRO». All right reserved.</div><div class="copy_two">Политика конфиденциальности</div></div>
</footer>