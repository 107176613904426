<mat-accordion class="faq-accordion">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-between p-relative"> 
            <h2>Какие места посетить в Таиланде?</h2>
            <div class="indicator-accordion"></div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="attractions-description">
        <span class="bold-text">Тур на Симиланские острова</span> вблизи провинции Пханг Нга. Красивейшее место, одно из лучших для снорклинга и дайвинга. Продолжительность экскурсии от одного дня до нескольких дней и ночей.
        <br />
        <span class="bold-text">Прогулка по островам Краби.</span> Пляж полуострова Рейли признан лучшим на Таиланде. На острове Туб можно прогуляться по песчаной косе. Нетронутая природа и роскошный вид острова Хаи поможет погрузиться в состояние умиротворения. Еще один остров провинции Краби — Пода. Здесь можно увидеть самых разнообразных морских обитателей. Острова Краби расположены близко друг от друга. Можно доплыть до любого за 20 минут.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-between p-relative"> 
            <h2>Какие места посетить в Таиланде?</h2>
            <div class="indicator-accordion"></div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="attractions-description">
        <span class="bold-text">Тур на Симиланские острова</span> вблизи провинции Пханг Нга. Красивейшее место, одно из лучших для снорклинга и дайвинга. Продолжительность экскурсии от одного дня до нескольких дней и ночей.
        <br />
        <span class="bold-text">Прогулка по островам Краби.</span> Пляж полуострова Рейли признан лучшим на Таиланде. На острове Туб можно прогуляться по песчаной косе. Нетронутая природа и роскошный вид острова Хаи поможет погрузиться в состояние умиротворения. Еще один остров провинции Краби — Пода. Здесь можно увидеть самых разнообразных морских обитателей. Острова Краби расположены близко друг от друга. Можно доплыть до любого за 20 минут.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-between p-relative"> 
            <h2>Какие места посетить в Таиланде?</h2>
            <div class="indicator-accordion"></div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="attractions-description">
        <span class="bold-text">Тур на Симиланские острова</span> вблизи провинции Пханг Нга. Красивейшее место, одно из лучших для снорклинга и дайвинга. Продолжительность экскурсии от одного дня до нескольких дней и ночей.
        <br />
        <span class="bold-text">Прогулка по островам Краби.</span> Пляж полуострова Рейли признан лучшим на Таиланде. На острове Туб можно прогуляться по песчаной косе. Нетронутая природа и роскошный вид острова Хаи поможет погрузиться в состояние умиротворения. Еще один остров провинции Краби — Пода. Здесь можно увидеть самых разнообразных морских обитателей. Острова Краби расположены близко друг от друга. Можно доплыть до любого за 20 минут.
      </p>
    </mat-expansion-panel>
</mat-accordion>