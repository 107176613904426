<button class="bg-black20 color-white black-next-btn d-flex items-center"
    matRipple
    [matRippleColor]="!insert ? '#20202070' : '#63636366'"
    [class.mobile-only-icon-btn]="mobileOnlyIcon"
    [class.justify-center]="!insert"
    [class.justify-between]="insert"
    [class.insert-background-hover]="insert"
    [class.insert-color]="insert">
    <div [class.text-center]="textCenter" [class.only-text]="textCenter" class="btn-text">{{text}}</div>

    <div class="icon-wrap color-white bg-white" *ngIf="isArrow" [class.insert-icon-wrap-color]="insert">
        <mat-icon [class.insert-mat-icon]="insert">keyboard_arrow_right</mat-icon>
    </div>
</button>

<div class="icon-wrap color-white bg-white only-icon-container" *ngIf="mobileOnlyIcon" [class.insert-icon-wrap-color]="insert">
    <mat-icon [class.insert-mat-icon]="insert">keyboard_arrow_right</mat-icon>
</div>