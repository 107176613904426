<article class="sport-news-card">
    <div class="news-content">
        <div class="image-container">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/89c4d817ac57cc75c6c4117afce3346f246aaa0f7609798fda8c072b76132d6c?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" class="news-image" alt="Sport news related image" />
            <div class="category-tag">
                <span class="category">Спорт</span>
            </div>
        </div>
        <div class="news-details">
            <h2 class="news-title">Что такое гражданство ЕС и как его получить?</h2>
            <div class="author-info">
                <div class="author-icon">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2b1d6a165e6d74f559b2c13935386a0b10271b0139e582c8bf968dbb2cb80976?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" class="author-avatar" alt="" />
                </div>
                <span class="author-name">Сергей Павлович</span>
            </div>
        </div>
    </div>
</article>