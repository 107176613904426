import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit, Signal, WritableSignal, afterNextRender, afterRender, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderDialogComponent } from '../../../../standalone/header-dialog/header-dialog.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'thaipro-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  isMain = signal(false);
  unsubscribe$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private injector: Injector
  ) {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.router.events.pipe(
      takeUntil(this.unsubscribe$),
      filter(event => event instanceof NavigationStart)
    ).subscribe((res) => {
      const event = res as NavigationStart;
      if(event.url === '/') {
        this.isMain.set(true);
      } else {
        this.isMain.set(false)
      }
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(HeaderDialogComponent, { width: '100%',
    });
  }

}
