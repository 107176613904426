<section class="community-section">
    <h2 class="section-title">Присоединяйся к сообществу</h2>
    <p class="section-description">Мы работаем, чтобы ваше пребывание в Тайланде было максимально комфортным</p>
    <div class="social-links">
    <a href="#" class="social-link" aria-label="Social Media Link 1">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d72a6dd4efd2ad52135bd55d1ac50b601d8d9d0d6421a0a204e96dcf6ed0725?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="social-icon" />
    </a>
    <a href="#" class="social-link" aria-label="Social Media Link 2">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/90c4e982cf44238fc12d7f7525ac07bc15e0b3e61a0637872a93e4043457cef3?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="social-icon" />
    </a>
    <a href="#" class="social-link" aria-label="Social Media Link 3">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/71f0fa8639fe5e2c79f04e8e67447872f0c819082b219fe0bb09697645edc654?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="social-icon" />
    </a>
    <a href="#" class="social-link" aria-label="Social Media Link 4">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bdcd1cf4f74f964de4dcb044a5446189c3b7659ba46ce36a93ddecb6255f4a3?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="social-icon" />
    </a>
    <a href="#" class="social-link" aria-label="Social Media Link 5">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/73231a45e0011f12e0055d303c88a73850d0cb63c8c2033abe267630c65c59c7?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="social-icon" />
    </a>
    <a href="#" class="social-link" aria-label="Social Media Link 6">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc60687d4646357356884b692abbe15afb3c3778dd212251595906250b98b659?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="social-icon" />
    </a>
    </div>
</section>