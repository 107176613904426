<header class="d-flex flex-column">
    <nav class="menu d-flex justify-between">
        <div class="logo" [class.mobile-hidden]="isMain()">
            <img src="/assets/images/logo.png" alt="#" routerLink="/">
        </div>
        <div class="logo logo-white items-center" *ngIf="isMain()">
            <img src="/assets/images/logo-white.png" alt="#" routerLink="/">
        </div>
        <div class="actions d-flex">
            <div class="geo d-flex items-center">
                <thaipro-icon name="Subtract"></thaipro-icon>
                <div class="geo-name fw-600" [matMenuTriggerFor]="cityMenu">Пхукет</div>
                <mat-menu #cityMenu="matMenu" class="menu-dropdown">
                    <button mat-menu-item>Бангкок</button>
                    <button mat-menu-item>Паттайа</button>
                    <button mat-menu-item>Самуи</button>
                    <button mat-menu-item>Краби</button>
                </mat-menu>
            </div>
            <!-- <div class="language d-flex items-center">
                <thaipro-icon name="flagthailand"></thaipro-icon>
            </div> -->
            <!-- <div class="currency d-flex items-center">
                <div class="curr-container">
                    <thaipro-icon name="curr"></thaipro-icon>
                </div>
                <div class="curr-name fw-600">USD</div>
            </div> -->
            <div class="buttons d-flex items-center">
                <!-- <button mat-fab class="search d-flex items-center"><mat-icon>search</mat-icon></button> -->
                <button mat-flat-button class="account fw-500 fz-14"><mat-icon>person</mat-icon> Войти </button>
            </div>
        </div>
        <div class="mobile-actions">
            <!-- <button mat-icon-button><mat-icon [class.mobile-white]="isMain()">search</mat-icon></button> -->
            <button mat-icon-button><mat-icon [class.mobile-white]="isMain()">person</mat-icon></button>
            <!-- <button mat-icon-button (click)="openDialog()">
                <thaipro-icon [class.mobile-white]="isMain()" class="menu-trigger" name="menutrigger"></thaipro-icon>
            </button> -->
        </div>
    </nav>
    <ul class="desktop-menu d-flex">
        <li class="desktop-menu-item d-flex">
            <div>Жильё</div>
            <div class="icon-container arrow-down-hover" [matMenuTriggerFor]="rentMenu">
                <thaipro-icon name="down"></thaipro-icon>
            </div>
            <mat-menu #rentMenu="matMenu" class="menu-dropdown">
                <button mat-menu-item>Продажа и аренда недвижимости</button>
                <button mat-menu-item>Снять отель или апартаменты</button>
            </mat-menu>
        </li>
        <li class="desktop-menu-item d-flex">
            <div>Авто и байки</div>
        </li>
        <!-- <li class="desktop-menu-item"><div>Афиша</div></li> -->
        <a class="desktop-menu-item link-hover" target="_blank" routerLink="/tour"><div>Экскурсии</div></a>
        <a class="desktop-menu-item link-hover" target="_blank" routerLink="/yachts"><div>Аренда Яхт</div></a>
        <a target="_blank" class="desktop-menu-item link-hover" routerLink="/services"><div>Услуги</div></a>
        <!-- <li class="desktop-menu-item"><div>Визы</div></li> -->
    </ul>
</header>