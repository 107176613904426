import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'thaipro-main-reviews',
  templateUrl: './main-reviews.component.html',
  styleUrl: './main-reviews.component.scss'
})
export class MainReviewsComponent {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    margin: 11,
    responsive: {
      0: {
        items: 1,
        margin: -50
      },
      400: {
        margin: -50,
        items: 1,
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  }
}
