import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-faq-card',
  templateUrl: './faq-card.component.html',
  styleUrl: './faq-card.component.scss'
})
export class FaqCardComponent {

}
