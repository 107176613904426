import { Component, Input } from '@angular/core';

@Component({
  selector: 'thaipro-black-button',
  templateUrl: './black-button.component.html',
  styleUrl: './black-button.component.scss'
})
export class BlackButtonComponent {
  @Input() isArrow: boolean = false;
  @Input() text: string = '';
  @Input() insert: boolean = false;
  @Input() textCenter: boolean = false;
  @Input() mobileOnlyIcon: boolean = false;
}
