<div class="title d-flex justify-between">
    <thaipro-block-tittle [title]="'Что клиенты думают о нас'"></thaipro-block-tittle>
    <div class="buttons d-flex hidden">
        <button class="bg-grayE9" mat-icon-button (click)="owlCar.prev()"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button class="bg-grayE9" mat-icon-button (click)="owlCar.next()"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
</div>
<owl-carousel-o ngSkipHydration [options]="customOptions" #owlCar>
    <ng-template carouselSlide>
        <thaipro-review></thaipro-review>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-review></thaipro-review>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-review></thaipro-review>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-review></thaipro-review>
    </ng-template>
</owl-carousel-o>
