import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-social-network',
  templateUrl: './social-network.component.html',
  styleUrl: './social-network.component.scss'
})
export class SocialNetworkComponent {

}
