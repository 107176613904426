<div class="title d-flex justify-between">
    <thaipro-block-tittle [title]="'О нас пишут'"></thaipro-block-tittle>
</div>

<owl-carousel-o ngSkipHydration [options]="customOptions">
    <ng-template carouselSlide>
        <div class="logo-container">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/65c2dcbaaa6d5f95e1ee63742dc7ebab899b7abf856e86cc16224709d472897d?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" class="logo-image logo-image-1" alt="Company logo 1" />
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="logo-container">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/56574d148c73e9baaacffa68df7a5e7e3502c899d672bddff0af3b11563ac8b1?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" class="logo-image logo-image-2" alt="Company logo 2" />
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="logo-container">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8dd4c588962e5d23cf634cfe86aa0ead681b327e859d166718449c1fa4ec286a?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" class="logo-image logo-image-3" alt="Company logo 3" />
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="logo-container">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ed1d532fb82486c5fd66f45150cfb3b952055d2ec620bb30998ccb160c3eb76c?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" class="logo-image logo-image-4" alt="Company logo 4" />
        </div>
    </ng-template>
</owl-carousel-o>