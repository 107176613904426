import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { IconComponent } from './components/icon/icon.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BlockTittleComponent } from './components/block-tittle/block-tittle.component';
import { RouterLink } from '@angular/router';
import { BlackButtonComponent } from './components/black-button/black-button.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ArticlesComponent } from './components/articles/articles.component';
import { FaqCardComponent } from './components/faq-card/faq-card.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MainReviewsComponent } from './components/main-reviews/main-reviews.component';
import { ReviewComponent } from '../../standalone/review/review.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { NewsComponent } from './components/news/news.component';
import { SocialNetworkComponent } from './components/social-network/social-network.component';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    IconComponent,
    BlockTittleComponent,
    BlackButtonComponent,
    ArticlesComponent,
    FaqCardComponent,
    MainReviewsComponent,
    NewsCardComponent,
    NewsComponent,
    SocialNetworkComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    CarouselModule,
    RouterLink,
    MatExpansionModule,
    MatRippleModule,
    MatMenuModule,
    ReviewComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BlockTittleComponent,
    BlackButtonComponent,
    ArticlesComponent,
    FaqCardComponent,
    MainReviewsComponent,
    NewsCardComponent,
    NewsComponent,
    SocialNetworkComponent
  ],
  providers: [
    provideClientHydration(withEventReplay()),
    provideHttpClient(withFetch()),
  ]
})
export class SharedModule { }
