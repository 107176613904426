<div class="title d-flex justify-between">
    <thaipro-block-tittle [title]="'Новости и статьи из Тайланда'"></thaipro-block-tittle>
    <div class="btn-container desktop-black-btn">
        <thaipro-black-button [isArrow]="true" [text]="'Смотреть всё'" (click)="openNewTab()"></thaipro-black-button>
    </div>
</div>

<owl-carousel-o ngSkipHydration [options]="customOptions">
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
</owl-carousel-o>

<owl-carousel-o ngSkipHydration [options]="customOptions" class="hidden" *ngIf="!isReduction">
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
    <ng-template carouselSlide>
        <thaipro-news-card></thaipro-news-card>
    </ng-template>
</owl-carousel-o>


<div class="mobile-black-btn">
    <thaipro-black-button [isArrow]="true" [text]="'Смотреть всё'" (click)="openNewTab()"></thaipro-black-button>
</div>