import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'thaipro-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent {

  @Input() isReduction: boolean = false;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    margin: 11,
    responsive: {
      0: {
        items: 1,
        margin: -50
      },
      400: {
        margin: -50,
        items: 1,
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  }

  constructor(private _router: Router){

  }

  openNewTab(): void {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['static', 'blog'])
    );
  
    window.open(url, '_blank');
  }
}
