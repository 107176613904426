import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-news-card',
  templateUrl: './news-card.component.html',
  styleUrl: './news-card.component.scss'
})
export class NewsCardComponent {

}
