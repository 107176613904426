import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'thaipro-articles',
  templateUrl: './articles.component.html',
  styleUrl: './articles.component.scss'
})
export class ArticlesComponent {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    margin: 11,
    responsive: {
      0: {
        items: 1,
        margin: -150
      },
      400: {
        margin: -150,
        items: 1,
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  }
}
