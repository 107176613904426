
<article class="review-card">
    <p class="review-text">Недавно вернулся из крутой поездки в Тайланд и могу сказать, что Тайланд действительно влюбил в себя после этой экскурсии! Особенно понравилось красоты природы. Грандиозные горы, бирюзовые озера, зеленые луга - всё это завораживает и оставляет сильные впечатления. <br /> Экскурсовод Роберт помог глубоко погрузиться в историю и культуру Осетии, узнать о традициях, особенностях местной...</p>
    
    <a href="#" class="trip-link">
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6b0f5ec357b528dce6d68a4724c4d2d28bf7e22465ac63f693b2d6f79c3ad2e?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="trip-icon" />
    <span class="trip-title">Поездка в группе «Осетия — любовь <br /> с первого взгляда!»</span>
    </a>
    
    <hr class="divider" />
    
    <footer class="reviewer">
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e62a2ccd02c3310086aad0d7a0590afc66d73fa431e5a682b781e97ef2228b4?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Reviewer's avatar" class="reviewer-avatar" />
    <div class="reviewer-info">
        <h3 class="reviewer-name">Full Name</h3>
        <div class="rating">
        <span class="visually-hidden">Rating: 5 out of 5 stars</span>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6bad18b819c2eabc4e9b84f8bbe9b42d272fbc2a17a7fa158b2c481885a93db3?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="star" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/dce263886bcdf7eb18dc0cc53b1ab69641e7cb7a19fdc9466ba2213f0f14189f?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="star" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6168bc3d15aa35e9326b302c16a43911299bf6e38aaad580787237872a677d4e?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="star" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6168bc3d15aa35e9326b302c16a43911299bf6e38aaad580787237872a677d4e?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="star" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6168bc3d15aa35e9326b302c16a43911299bf6e38aaad580787237872a677d4e?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="star" />
        </div>
    </div>
    </footer>
</article>
